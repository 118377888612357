<template>
	<section class="section team">
		<div class="container has-text-centered">
			<h2 class="title title-font">{{ $t("MEET_THE_TEAM") }}</h2>
			<div class="columns is-centered">
				<div class="column is-1 is-hidden-mobile" />
				<div class="column">
					<div class="profile-image martin" />
					<p class="is-size-5">Martin Froehler</p>
					<p>CEO | Cofounder</p>
					<a
						href="https://www.linkedin.com/in/martinfroehler/"
						target="_blank"
						rel="nofollow"
						alt="Martin LinkedIn"
					>
						<b-icon icon="fab fa-linkedin-in" pack="fab" size="is-small" />
					</a>
				</div>
				<div class="column">
					<div class="profile-image denis" />
					<p class="is-size-5">Denis Bykov</p>
					<p>CPO | Cofounder</p>
					<a
						href="https://www.linkedin.com/in/bykovdenis/"
						target="_blank"
						rel="nofollow"
						alt="Denis LinkedIn"
					>
						<b-icon icon="fab fa-linkedin-in" pack="fab" size="is-small" />
					</a>
				</div>
				<div class="column">
					<div class="profile-image thomas" />
					<p class="is-size-5">Thomas Wiesner</p>
					<p>CTO | Blockchain Wizard</p>
					<a
						href="https://www.linkedin.com/in/thomas-wiesner/"
						target="_blank"
						rel="nofollow"
						alt="Thomas LinkedIn"
					>
						<b-icon icon="fab fa-linkedin-in" pack="fab" size="is-small" />
					</a>
				</div>
				<div class="column is-1 is-hidden-mobile" />
			</div>

			<!-- Join Us Section -->
			<h3 class="title title-font mt-80 is-spaced">{{ $t("JOIN_US") }}</h3>
			<div class="subtitle">
				<p v-html="$t('TOP_STARTUP_VIENNA')" />
				<p v-html="$t('DEUTSCHE_STARTUP')" />
			</div>
			<div class="columns is-centered">
				<div class="column is-hidden-mobile" />
				<div class="column is-narrow">
					<a
						href="https://angel.co/company/morpher/jobs"
						target="_blank"
						rel="nofollow"
					>
						<button tag="button" class="cta-btn button is-angel">
							<b-icon
								icon="fab fa-angellist"
								pack="fab"
								size="is-small"
								style="marginRight: 2px"
							/>
							{{
								$t("JOBS_ON", {
									platform: "AngelList",
								})
							}}
						</button>
					</a>
				</div>
				<div class="column is-narrow">
					<a
						href="https://www.linkedin.com/company/morpher/jobs/"
						target="_blank"
						rel="nofollow"
					>
						<button tag="button" class="cta-btn button is-linkedin">
							<b-icon
								icon="fab fa-linkedin-in"
								pack="fab"
								size="is-small"
								style="marginRight: 2px"
							/>
							{{
								$t("JOBS_ON", {
									platform: "LinkedIn",
								})
							}}
						</button>
					</a>
				</div>
				<div class="column is-hidden-mobile" />
			</div>
			<p v-html="$t('DREAM_ROLE')" />

			<!-- Draper Quote -->
			<div class="columns is-centered has-text-centered">
				<div class="column is-9 is-8-fullhd">
					<p class="has-text-weight-medium quote-text">
						<span class="is-size-1">“</span>
						Free markets are awesome and liquidity creates wealth. Morpher is
						creating infinite wealth!
						<span class="is-size-1">”</span>
					</p>
					<br />
					<p class="has-text-weight-medium is-size-4">- Tim Draper</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Team",
};
</script>

<style scoped>
.columns {
	margin-top: 40px;
}
.profile-image {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	width: 84px;
	height: 84px;
	border-radius: 100%;
	margin: 10px auto;
	box-shadow: 0 4px 8px rgba(182, 191, 210, 0.5);
}
.profile-image.martin {
	background-image: url("../../assets/img/about/martin_headshot.png");
}
.profile-image.denis {
	background-image: url("../../assets/img/about/denis_headshot.jpg");
}
.profile-image.thomas {
	background-image: url("../../assets/img/about/thomas_headshot.png");
}
.section {
	padding-bottom: 80px;
	padding-top: 80px;
}
.team a {
	color: #666;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.team a:hover {
	color: #000;
	transition-delay: 0s;
	transition-duration: 0.2s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.mt-80 {
	margin-top: 80px;
}
.cta-btn.button {
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1.25rem;
	height: 3.25rem;
	padding: 0 1rem;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button:hover {
	background-color: #00c386;
	border-color: #00c386;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-angel:hover {
	background-color: #333;
	border-color: #333;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn.button.is-linkedin:hover {
	background-color: #2867b2;
	border-color: #2867b2;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.35s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.quote-text {
	margin-top: 100px;
	line-height: 2.75rem;
	font-family: "Rubik", sans-serif;
	font-size: 1.75rem;
	color: #00a16f;
}
</style>
