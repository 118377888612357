<template>
  <section class="hero is-medium">
    <Navbar />

    <div class="hero-body has-text-centered">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <h1 class="title is-1 title-font is-spaced">{{ $t('ABOUT_US') }}</h1>
            <p class="subtitle is-size-4">
              {{ $t('ESTABLISHED_TEXT') }}
              <br>
              {{ $t('BUILT_TEXT') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Navbar from "@/components/partials/Navbar";

export default {
	name: "Hero",
	components: {
		Navbar
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
	background-color: #f5f1f9;
	/* background-color: #fdf1f3; */
}
</style>
