<template>
  <section class="section">
    <div class="container has-text-centered">
      <h2 class="title title-font">{{ $t('OUR_INVESTORS') }}</h2>
      <div class="columns is-centered is-vcentered">
        <div class="column is-hidden-mobile" />
        <div class="column">
          <a href="https://draper.vc/" target="_blank">
            <img
              class="draper-logo"
              src="@/assets/img/about/draper_logo_color.png"
              alt="Draper Associates Logo"
            >
          </a>
        </div>
        <div class="column">
          <a href="https://www.apex.ventures/" target="_blank">
            <img class="apex-logo" src="@/assets/img/about/apex_logo.png" alt="Apex Ventures Logo">
          </a>
        </div>
        <div class="column is-hidden-mobile" />
      </div>
      <p class="subtitle mid-spacing">{{ $t('MORPHER_GRADUATED') }}:</p>
      <div class="columns is-centered is-vcentered">
        <div class="column is-hidden-mobile" />
        <div class="column">
          <a href="https://startx.com/" target="_blank">
            <img class="startx-logo" src="@/assets/img/about/startx_logo_color.png" alt="StartX Logo">
          </a>
        </div>
        <div class="column">
          <a href="https://vshub.stanford.edu/" target="_blank">
            <img
              class="svs-logo"
              src="@/assets/img/about/svs_logo.png"
              alt="Stanford Venture Studio Logo"
            >
          </a>
        </div>
        <div class="column is-hidden-mobile" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: "Investors"
};
</script>

<style scoped>
.section {
	padding-bottom: 80px;
	padding-top: 80px;
}
.draper-logo {
	max-width: 280px;
}
.apex-logo {
	max-height: 150px;
}
.startx-logo {
	max-width: 160px;
}
.svs-logo {
	max-height: 70px;
}
.mid-spacing {
	margin-top: 60px;
}
</style>