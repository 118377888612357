<template>
  <section class="section is-medium">
    <div class="container">
      <div class="columns is-centered has-text-centered">
        <div class="column is-11-tablet is-10-fullhd has-text-white">
          <p class="is-size-3 has-text-weight-semibold mission-text">
            {{ $t('MISSION_STATEMENT') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: "MissionStatement"
};
</script>

<style scoped>
.section {
	background-image: url("../../assets/img/about/morpher_building_logo.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #000;
}
.mission-text {
	line-height: 2.75rem;
}
</style>