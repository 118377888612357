<template>
  <div>
    <Hero />
    <MissionStatement />
    <Investors />
    <OurStory />
    <Team />
    <Footer :disclaimerContent="0" />
  </div>
</template>

<script>
import Hero from "@/components/about/Hero";
import MissionStatement from "@/components/about/MissionStatement";
import Investors from "@/components/about/Investors";
import OurStory from "@/components/about/OurStory";
import Team from "@/components/about/Team";
import Footer from "@/components/partials/Footer";

export default {
	name: "About",
	components: {
		Hero,
		MissionStatement,
		Investors,
		OurStory,
		Team,
		Footer,
	},
	data() {
		return {
			headParams: {
				title: this.$t('ABOUT_US'),
				description: this.$t('meta.ABOUT_US_DESC'),
			},
		};
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
	},
	head() {
		return {
			title: {
				inner: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type' },
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_wall_logo.png",
					id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
					id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_wall_logo.png",
					id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com" + this.$route.path,
					id: "canonical",
				},
			],
		};
	},
};
</script>
