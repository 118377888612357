<template>
  <section class="section">
    <div class="container">
      <h2 class="title title-font has-text-centered">{{ $t('OUR_STORY') }}</h2>
      <div class="columns is-centered">
        <div class="column is-10">
          <p class="is-size-5">{{ $t('OUR_STORY_DESC_1') }}</p>
          <br>
          <p class="is-size-5">{{ $t('OUR_STORY_DESC_2') }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: "OurStory"
};
</script>

<style scoped>
.section {
	background-color: #f9f8f9;
	padding-bottom: 80px;
	padding-top: 80px;
}
</style>